<template>
  <div v-if="getProducts.status !== 'success'" class="slider-loader">
    <span class="ui-loader" />
  </div>
  <ProductCarousel
    v-else
    :title="title"
    :products="getFilteredProducts(getProducts.data)"
  />
</template>

<script setup>
import ProductCarousel from "~/modules/shared/sliders/ProductCarousel.vue";
import { Product } from "~/models/product.model";
import { getProductsByLabel } from "~/utils/getters.utils";

const props = defineProps({
  label: { type: String, required: true },
  title: { type: String, required: false, default: "" },
});

const getProducts = await getProductsByLabel(props.label);

const getFilteredProducts = (products) => {
  return products
    .filter((product) => product.isOrderable)
    .map((product) => new Product(product));
};
</script>

<style lang="scss" scoped>
.slider-loader {
  @include flex-container(row, center, center);
}
</style>
